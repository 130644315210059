import * as React from 'react';
import { useSearchParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from "react-router-dom";
import { Controller, useFormContext } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import PrintIcon from '@mui/icons-material/Print';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { triggerBase64ToUrl, triggerBase64Download } from 'components/Common/triggerBase64Download';
import { styled } from '@mui/material/styles';
import ThemeContext from 'components/ThemeContext';

dayjs.extend(utc);
export default function MenuActions({ Client, children, initial, handleClickSearch, handleSave, handleConfirmDelete, id, setError, setSuccess }) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { handleSubmit } = useFormContext();
    const baseUrl = useLocation().pathname.split('/').slice(0, 3).join('/');

    const handleBack = () => {
        navigate({
            pathname: baseUrl,
            search: searchParams.toString()
        });
    }
    return (
        <>
            <Grid container>
                {initial
                    ?
                    <>
                        {handleClickSearch &&
                            <Tooltip title="Buscar">
                                <IconButton onClick={handleSubmit(handleClickSearch)}>
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {(handleSave || handleConfirmDelete) &&
                            <Tooltip title="Regresar">
                                <IconButton onClick={() => handleBack()}>
                                    <UndoIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {!initial.isDisabled && handleSave &&
                            <Tooltip title="Guardar">
                                <IconButton onClick={handleSubmit(handleSave)}>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {initial && initial.actions.map((action, i) =>
                            <ActionButton key={i} action={action} navigate={navigate} searchParams={searchParams} baseUrl={baseUrl}
                                setError={setError} id={id} setSuccess={setSuccess} handleClickSearch={handleClickSearch} Client={Client} />
                        )}
                    </>
                    :
                    <>
                        <Skeleton variant="rectangular" width={24} height={24} sx={{ m: 1 }} />
                        <Skeleton variant="rectangular" width={24} height={24} sx={{ m: 1 }} />
                        <Skeleton variant="rectangular" width={24} height={24} sx={{ m: 1 }} />
                        <Skeleton variant="rectangular" width={24} height={24} sx={{ m: 1 }} />
                    </>
                }
            </Grid>
            {initial &&
                (
                    (initial.message)
                ?
                    <Alert severity="error">{initial.message}</Alert>
                :
                    handleConfirmDelete &&
                    <Alert severity="error" sx={{ mb: 1 }}>
                        ¿Desea eliminar?
                        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                            <Button variant="contained" color="error" onClick={handleConfirmDelete}>Sí</Button>
                            <Button variant="outlined" onClick={handleBack} >No</Button>
                        </Stack>
                    </Alert>
                )
            }
            {children}
        </>
    );
}
const ActionButton = ({ action, navigate, searchParams, baseUrl, setError, id, setSuccess, handleClickSearch, Client }) => {
    const [blobPrint, setBlobPrint] = React.useState(null);
    const { setLoadingGlobal } = React.useContext(ThemeContext);

    const handleAdd = () => {
        navigate({
            pathname: `${baseUrl}/Agregar`,
            search: searchParams.toString()
        });
    }

    const handleEdit = () => {
        if (id) {
            navigate({
                pathname: `${baseUrl}/Editar/${id}`,
                search: searchParams.toString()
            });
        }
        else {
            setError('Debe seleccionar al menos uno');
        }
    }

    const handleDelete = () => {
        if (id) {
            navigate({
                pathname: `${baseUrl}/Eliminar/${id}`,
                search: searchParams.toString()
            });
        }
        else {
            setError('Debe seleccionar al menos uno');
        }
    }

    const handleSendSunat = () => {
        if (id) {
            const client = new Client();
            setLoadingGlobal(true);
            client.sendSunat({ id: id }).then(
                result => {
                    setLoadingGlobal(false);
                    setSuccess(result);
                    handleClickSearch && handleClickSearch();
                },
                error => {
                    setLoadingGlobal(false);
                    setError(error.detail);
                    handleClickSearch && handleClickSearch();
                }
            );
        }
        else {
            setError('Debe seleccionar al menos uno');
        }
    }

    const handlePrint = () => {
        if (id) {
            const client = new Client();
            setLoadingGlobal(true);
            client.pdf({ id: id }).then(
                result => {
                    setLoadingGlobal(false);
                    setBlobPrint(triggerBase64ToUrl(result.contentType, result.content));
                },
                error => {
                    setLoadingGlobal(false);
                    setError(error.detail);
                }
            );
        }
        else {
            setError('Debe seleccionar al menos uno');
        }
    }

    const [fn, setFn] = React.useState(null);
    const [icon, setIcon] = React.useState(null);
    React.useEffect(() => {
        if (action.desc === 'Agregar') {
            setFn(() => handleAdd);
            setIcon(<InsertDriveFileIcon />)
        }
        else if (action.desc === 'Editar') {
            setFn(() => handleEdit);
            setIcon(<BorderColorIcon />)
        }
        else if (action.desc === 'Eliminar') {
            setFn(() => handleDelete);
            setIcon(<DeleteIcon />)
        }
        else if (action.desc === 'Imprimir') {
            setFn(() => handlePrint);
            setIcon(<PrintIcon />)
        }
        else if (action.desc === 'Enviar a SUNAT') {
            setFn(() => handleSendSunat);
            setIcon(<CloudUploadIcon />)
        }
        else if (action.desc === 'Exportar Facturación Electrónica') {
            setFn(null);
            setIcon(<DownloadPopper id={id} setError={setError} Client={Client} setLoadingGlobal={setLoadingGlobal} />)
        }
    }, [id]);

    return (
        <>
            <Tooltip key={action.id} title={action.desc}>
                {fn ?
                    <IconButton onClick={fn}>
                        {icon}
                    </IconButton>
                    :
                    <div>
                        {icon}
                    </div>                    
                }
            </Tooltip>
            {blobPrint &&
                <DialogPrint blob={blobPrint} onClose={() => setBlobPrint(null)} />
            }
        </>
    );
}

const DownloadPopper = ({ id, setError, Client, setLoadingGlobal }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = (type) => {
        if (id) {
            const client = new Client();
            setLoadingGlobal(true);
            client.download({ id: id, type: type }).then(
                result => {
                    setLoadingGlobal(false);
                    triggerBase64Download(result.contentType, result.content, result.fileName);
                },
                error => {
                    setLoadingGlobal(false);
                    setError(error.detail);
                }
            );
        }
        else {
            setError('Debe seleccionar al menos uno');
        }
        handleClose();
    }

    return (
        <>
            <IconButton onClick={handleClick} >
                <CloudDownloadIcon />
            </IconButton>
            <Menu
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleItemClick('xml')}>XML</MenuItem>
                <MenuItem onClick={() => handleItemClick('pdf')}>PDF</MenuItem>
                <MenuItem onClick={() => handleItemClick('cdr')}>CDR</MenuItem>
            </Menu>
        </>
    );
}

const Iframe = styled('iframe')(() => ({
    border: 'none',
    height: '100%',
    width: '100%',
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DialogPrint = ({ blob, onClose }) => {
    const [show, setShow] = React.useState(false);
    React.useEffect(() => {
        setShow(true);
    },[]);
    return (
        <Dialog
            fullScreen
            open={show}
            onClose={onClose}
            fullWidth
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        Vista previa
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Iframe src={blob} />
        </Dialog>
    );
}