import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Muller300 from '../fonts/9ff57e16737552a50301198eaae83cfb.woff2';
import Muller400 from '../fonts/a6c10f4967900a6f1d39f6996d20fec4.woff2';
import Muller700 from '../fonts/892951b494976f7fd57f5d460c690966.woff2';
import Muller800 from '../fonts/ae28b5730ba85dc3a4ad1ccfcf376ff0.woff2';
import { esES } from '@mui/x-data-grid';
import ThemeContext from 'components/ThemeContext';

export default function Theme({ children}) {
    const theme = createTheme({
        typography: {
            fontFamily: 'Muller, Arial',
        },
        palette: {
            primary: {
                main: '#634a7b',
                dark: '#391f4a',
            },
            secondary: {
                main: '#fc612a',
            },
            tertiary: {
                main: '#391f4a',
                contrastText: '#fff',
            },
            background: {
                default: '#F4F5FA'
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                    @font-face {
                        font-family: 'Muller';
                        src: url(${Muller300}) format('woff2');
                        font-weight: 300;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'Muller';
                        src: url(${Muller400}) format('woff2');
                        font-weight: 400;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'Muller';
                        src: url(${Muller700}) format('woff2');
                        font-weight: 500;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'Muller';
                        src: url(${Muller700}) format('woff2');
                        font-weight: 600;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'Muller';
                        src: url(${Muller700}) format('woff2');
                        font-weight: 700;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'Muller';
                        src: url(${Muller800}) format('woff2');
                        font-weight: 800;
                        font-style: normal;
                    }
                `,
            }, 
            MuiTextField: {
                defaultProps: {
                    size: "small",
                    margin: "dense",
                },
            },
            MuiSelect: {
                defaultProps: {
                    size: "small",
                    margin: "dense",
                },
            },
            MuiTable: {
                defaultProps: {
                    size: "small",
                },
            },
            MuiTablePagination: {
                defaultProps: {
                    component:"div",
                    labelRowsPerPage: "Filas por página:",
                    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`,
                    rowsPerPageOptions: [10, 25, 100],
                }
            },
            MuiDataGrid: {
                defaultProps: {
                    density: "compact",
                    disableColumnMenu: true,
                    disableColumnSelector: true,
                    pageSize:10,
                    rowsPerPageOptions: [10],
                    autoHeight: true,
                }
            },
            MuiLink: {
                defaultProps: {
                    underline: "hover",
                }
            }
        },
    }, esES);
    const [loadingGlobal, setLoadingGlobal] = React.useState(false);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }}
                open={loadingGlobal}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ThemeContext.Provider value={{ setLoadingGlobal }}>
                {children}
            </ThemeContext.Provider>
        </ThemeProvider>
    )
};