import * as React from 'react';
import Typography from '@mui/material/Typography';

export default function PasswordSent() {
    return (
        <React.Fragment>
            <Typography>
                Contraseña enviada
            </Typography>
        </React.Fragment>
    );
}