import * as React from 'react';
import { Box, CircularProgress, FormControl, Typography, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { AuthClient } from '../Common/web-api-client.ts';
import { useSearchParams, useNavigate } from "react-router-dom";

export default function ResetPassword(props) {
    const [initialError, setinitialError] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const { control, handleSubmit, formState: { errors }, setValue, getValues, setFocus } = useForm();
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const client = new AuthClient();
        client.validResetPassword(searchParams.get("k")).then(
            result => {
                setinitialError(false);
            },
            error => {
                setinitialError(error.detail);
            }
        );
    }, []);
    React.useEffect(() => {
        if (initialError !== null && !initialError) {
            setFocus("password");
        }
    }, [initialError]);

    const onSubmit = data => {
        setLoading(true);

        data.token = searchParams.get("k");
        let client = new AuthClient();
        client.resetPassword(data).then(
            result => {
                navigate("../");
            },
            error => {
                setLoading(false);
                setError(error.detail);
            }
        );
    }

    return ((initialError !== null) ?
        initialError ?
            <Typography>
                {initialError}
            </Typography>
            :
            <>
                <Typography component="h1" variant="h6">
                    Resetear contraseña
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=''
                            rules={{
                                required: 'Campo requerido',
                                minLength: {
                                    value: 6,
                                    message: "Mínimo 6 caracteres"
                                },
                            }}
                            render={({ field: { ref, ...field } }) =>
                                <TextField
                                    {...field}
                                    type="password"
                                    inputRef={ref}
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    label="Nueva contraseña"
                                    margin="normal"
                                    disabled={loading}
                                />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <Controller
                            name="newPassword"
                            control={control}
                            defaultValue=''
                            rules={{
                                required: 'Campo requerido',
                                validate: {
                                    emailEqual: v => {
                                        return (v === getValues('password')) || 'Contraseñas no coinciden'
                                    },
                                },
                                minLength: {
                                    value: 6,
                                    message: "Mínimo 6 caracteres"
                                },
                            }}
                            render={({ field: { ref, ...field } }) =>
                                <TextField
                                    {...field}
                                    type="password"
                                    inputRef={ref}
                                    error={!!errors.newPassword}
                                    helperText={errors?.newPassword?.message}
                                    label="Repita contraseña"
                                    margin="normal"
                                    disabled={loading}
                                />
                            }
                        />
                    </FormControl>
                    <Box sx={{ mt: 2 }}/>
                    {error &&
                        <Alert severity="error">{error}</Alert>
                    }
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                    >
                        Aceptar
                    </LoadingButton>
                </form>
            </>
        :
        <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
    );
}