import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import { Controller, useFormContext } from 'react-hook-form';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ModalPerson from 'components/Modals/ModalPerson';
import ModalItem from 'components/Modals/ModalItem';
import ClearIcon from '@mui/icons-material/Clear';
export default function Content({ initial, Client }) {
    const { control, formState: { errors }, setValue, getValues, watch } = useFormContext();
    const details = watch('details', []);
    const [docSeries, setDocSeries] = React.useState(initial.docSeries);
    const [originProvinces, setOriginProvinces] = React.useState(initial.originProvinces);
    const [originDistricts, setOriginDistricts] = React.useState(initial.originDistricts);
    const [deliveryProvinces, setDeliveryProvinces] = React.useState(initial.deliveryProvinces);
    const [deliveryDistricts, setDeliveryDistricts] = React.useState(initial.deliveryDistricts);
    const isDisabled = initial.isDisabled;

    const watchId = watch("id", 0);
    const watchOriginPersonId = watch("originPersonId", null);
    const watchDeliveryPersonId = watch("deliveryPersonId", null);
    const watchDocSerie = watch("docSerie", initial.docSeries?.[0].id);
    const watchStatus = watch("status", true);    

    const [showModalOriginPerson, setShowModalOriginPerson] = React.useState(false);
    const [showModalDeliveryPerson, setShowModalDeliveryPerson] = React.useState(false);
    function selectOriginPerson(_person) {
        setValue("originPersonId", _person.id);
        setValue("originPersonDocumentType", _person.codeType);
        setValue("originPersonDocumentNumber", _person.code);
        setValue("originPersonName", _person.name);
        setShowModalOriginPerson(false);
    }
    function clearOriginPerson() {
        setValue("originPersonId", null);
        setValue("originPersonDocumentType", '6');
        setValue("originPersonDocumentNumber", '');
        setValue("originPersonName", '');
    }
    const handleOriginPersonClose = () => {
        setShowModalOriginPerson(false);
    }
    function selectDeliveryPerson(_person) {
        setValue("deliveryPersonId", _person.id);
        setValue("deliveryPersonDocumentType", _person.codeType);
        setValue("deliveryPersonDocumentNumber", _person.code);
        setValue("deliveryPersonName", _person.name);
        setShowModalDeliveryPerson(false);
    }
    function clearDeliveryPerson() {
        setValue("deliveryPersonId", null);
        setValue("deliveryPersonDocumentType", '6');
        setValue("deliveryPersonDocumentNumber", '');
        setValue("deliveryPersonName", '');
    }
    const handleDeliveryPersonClose = () => {
        setShowModalDeliveryPerson(false);
    }

    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [showModalItem, setShowModalItem] = React.useState(false);

    function selectItem(_item) {
        const _details = details.slice();
        _details[selectedIndex].itemId = _item.id;
        _details[selectedIndex].code = _item.code;
        _details[selectedIndex].name = _item.name;
        setValue("details", _details);
        setShowModalItem(false);
    }
    function clearItem(index) {
        const _details = details.slice();
        _details[index].itemId = 0;
        _details[index].code = '';
        _details[index].name = '';
        setValue("details", _details);
    }
    const handleItemClose = () => {
        setShowModalItem(false);
    }

    const handleChangeDocTypeId = (docTypeId) => {
        setDocSeries([]);
        setValue('docNumber', null);
        const client = new Client();
        client.getDocSeries(docTypeId).then(
            result => {
                setDocSeries(result);
                const docSerie = result[0].id;
                setValue('docSerie', docSerie)
                handleChangeDocSerie(docTypeId, docSerie)
            },
            error => {
            }
        );
    }
    const handleChangeDocSerie = (docTypeId, docSerie) => {
        setValue('docNumber', null);
        const client = new Client();
        client.getDocNumber(docTypeId, docSerie).then(
            result => {
                setValue('docNumber', result);
            },
            error => {
            }
        );
    }

    const handleChangeOriginDepartment = (department) => {
        setOriginProvinces([]);
        setOriginDistricts([]);
        const client = new Client();
        client.getProvinces(department).then(
            result => {
                setOriginProvinces(result);
                const provinceId = result[0].id;
                setValue('originProvince', provinceId)
                handleChangeOriginProvince(provinceId)
            },
            error => {
            }
        );
    }
    const handleChangeOriginProvince = (province) => {
        setOriginDistricts([]);
        const client = new Client();
        client.getDistricts(province).then(
            result => {
                setOriginDistricts(result);
                setValue('originDistrict', result[0].id)
            },
            error => {
            }
        );
    }

    const handleChangeDeliveryDepartment = (department) => {
        setDeliveryProvinces([]);
        setDeliveryDistricts([]);
        const client = new Client();
        client.getProvinces(department).then(
            result => {
                setDeliveryProvinces(result);
                const provinceId = result[0].id;
                setValue('deliveryProvince', provinceId)
                handleChangeDeliveryProvince(provinceId)
            },
            error => {
            }
        );
    }
    const handleChangeDeliveryProvince = (province) => {
        setDeliveryDistricts([]);
        const client = new Client();
        client.getDistricts(province).then(
            result => {
                setDeliveryDistricts(result);
                setValue('deliveryDistrict', result[0].id)
            },
            error => {
            }
        );
    }

    const handleAddItem = () => {
        setValue('details', [...details, { itemId: 0 }]);
    }

    const handleClickDeleteDetail = (i) => {
        setValue('details', [
            ...details.slice(0, i),
            ...details.slice(i + 1)
        ]);
    }

    return (initial &&
        <>
            <Container maxWidth="md" disableGutters sx={{ marginLeft: 0 }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="date"
                                control={control}
                                defaultValue={dayjs().utc().startOf('day')}
                                rules={{ required: 'Campo requerido' }}
                                render={({ field: { ref, ...field } }) =>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" dateLibInstance={dayjs.utc}>
                                        <DatePicker
                                            {...field}
                                            inputRef={ref}
                                            label="Fecha"
                                            maxDate={dayjs().utc().startOf('day')}
                                            slotProps={{
                                                textField: {
                                                    error: !!errors.date,
                                                    helperText: errors?.date?.message,
                                                },
                                            }}
                                            disabled={isDisabled}
                                        />
                                    </LocalizationProvider>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {initial.docTypes
                            ?
                            <FormControl fullWidth>
                                <Controller
                                    name="docTypeId"
                                    control={control}
                                    defaultValue={initial.docTypes[0].id}
                                    render={({ field }) =>
                                        <TextField select
                                            {...field}
                                            label="Tipo de documento"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleChangeDocTypeId(e.target.value);
                                            }}
                                        >
                                            {initial.docTypes.map((x) =>
                                                <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                            )}
                                        </TextField>
                                    }
                                />
                            </FormControl>
                            :
                            <TextField
                                label="Tipo de documento"
                                value={getValues("docTypeIdDesc")}
                                disabled={true}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {initial.docSeries
                            ?
                            <FormControl fullWidth>
                                <Controller
                                    name="docSerie"
                                    control={control}
                                    defaultValue={initial.docSeries[0].id}
                                    render={({ field }) =>
                                        <TextField select
                                            {...field}
                                            label="Serie"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleChangeDocSerie(getValues('docTypeId'), e.target.value);
                                            }}
                                        >
                                            {docSeries.map((x) =>
                                                <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                            )}
                                        </TextField>
                                    }
                                />
                            </FormControl>
                            :
                            <TextField
                                label="Serie"
                                value={getValues("docSerie")}
                                disabled={true}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>                        
                        {watchId === 0 
                            ?
                            <FormControl fullWidth>
                                <Controller
                                    name="docNumber"
                                    control={control}
                                    defaultValue={initial.docNumber}
                                    rules={{
                                        required: 'Campo requerido',
                                        validate: {
                                            positive: v => parseInt(v) > 0 || 'Valor inválido'
                                        }
                                    }}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            label="Número"
                                            type="number"
                                            error={!!errors.docNumber}
                                            helperText={errors?.docNumber?.message}
                                        />
                                    }
                                />
                            </FormControl>
                            :
                            <TextField
                                label="Número"
                                value={getValues("docNumber")}
                                disabled={true}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="grossWeight"
                                control={control}
                                rules={{
                                    required: 'Campo requerido',
                                    validate: {
                                        positive: v => parseInt(v) > 0 || 'Valor inválido'
                                    }
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Peso bruto (kg)"
                                        type="number"
                                        error={!!errors.grossWeight}
                                        helperText={errors?.grossWeight?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MonitorWeightIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="licensePlate"
                                control={control}
                                rules={{
                                    required: 'Campo requerido'
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Placa de vehículo"
                                        error={!!errors.licensePlate}
                                        helperText={errors?.licensePlate?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocalShippingIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>                    
                    <Grid item xs={12} sm={6} md={3}>
                    {initial.reasons
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="reasonCode"
                                control={control}
                                rules={{
                                    required: 'Campo requerido'
                                }}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Motivo"
                                        error={!!errors.reasonCode}
                                        helperText={errors?.reasonCode?.message}
                                        disabled={isDisabled}
                                    >
                                        {initial.reasons.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Motivo"
                            value={getValues("reasonCode")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="status"
                                        control={control}
                                        defaultValue={true}
                                        render={({ field }) => (
                                            <Switch
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                                disabled={isDisabled}
                                            />
                                        )}
                                    />
                                }
                                label={watchStatus ? "Activo" : "Inactivo"} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 1 }}>Conductor</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                    {initial.documentTypes
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="driverDocumentType"
                                control={control}
                                defaultValue={'1'}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Tipo de documento"
                                        disabled={isDisabled}
                                    >
                                        {initial.documentTypes.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Tipo de documento"
                            value={getValues("driverDocumentType")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="driverDocumentNumber"
                                control={control}
                                rules={{
                                    required: 'Campo requerido',
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Nro. de documento"
                                        error={!!errors.driverDocumentNumber}
                                        helperText={errors?.driverDocumentNumber?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BadgeIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="driverNames"
                                control={control}
                                rules={{
                                    required: 'Campo requerido',
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Nombres"
                                        error={!!errors.driverNames}
                                        helperText={errors?.driverNames?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="driverLastNames"
                                control={control}
                                rules={{
                                    required: 'Campo requerido',
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Apellidos"
                                        error={!!errors.driverLastNames}
                                        helperText={errors?.driverLastNames?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Controller
                                name="driverLicense"
                                control={control}
                                rules={{
                                    required: 'Campo requerido',
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Licencia de conducir"
                                        error={!!errors.driverLicense}
                                        helperText={errors?.driverLicense?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CreditCardIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    {!watchDocSerie.startsWith('T') &&
                    <>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 1 }}>Remitente</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {initial.documentTypes
                            ?
                            <FormControl fullWidth>
                                <Controller
                                    name="originPersonDocumentType"
                                    control={control}
                                    defaultValue={'6'}
                                    render={({ field }) =>
                                        <TextField select
                                            {...field}
                                            label="Tipo de documento"
                                            disabled={isDisabled || watchOriginPersonId !== null}
                                        >
                                            {initial.documentTypes.map((x) =>
                                                <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                            )}
                                        </TextField>
                                    }
                                />
                            </FormControl>
                            :
                            <TextField
                                label="Tipo de documento"
                                value={getValues("originPersonDocumentType")}
                                disabled={true}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="originPersonDocumentNumber"
                                control={control}
                                rules={{
                                    required: 'Campo requerido'
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Nro. de documento"
                                        error={!!errors.originPersonDocumentNumber}
                                        helperText={errors?.originPersonDocumentNumber?.message}
                                        disabled={isDisabled || watchOriginPersonId !== null}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BadgeIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Controller
                                name="originPersonName"
                                control={control}
                                rules={{
                                    required: 'Campo requerido'
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Nombre completo"
                                        error={!!errors.originPersonName}
                                        helperText={errors?.originPersonName?.message}
                                        disabled={isDisabled || watchOriginPersonId !== null}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="auto">
                        <FormControl sx={{ mt: 1 }}>
                            {watchOriginPersonId
                                ?
                                <Tooltip title="Limpiar">
                                    <Button variant="outlined" onClick={clearOriginPerson} disabled={isDisabled}>
                                        <ClearIcon />
                                    </Button>
                                </Tooltip>
                                :
                                <Tooltip title="Buscar">
                                    <Button variant="outlined" onClick={() => setShowModalOriginPerson(true)} disabled={isDisabled}>
                                        <SearchIcon />
                                    </Button>
                                </Tooltip>
                            }
                        </FormControl>
                    </Grid>
                    </>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 1 }}>Partida</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Controller
                                name="originAddress"
                                control={control}
                                rules={{
                                    required: 'Campo requerido',
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Dirección"
                                        error={!!errors.originAddress}
                                        helperText={errors?.originAddress?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MapsHomeWorkIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {initial.departments 
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="originDepartment"
                                control={control}
                                defaultValue={"15"}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Departamento"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChangeOriginDepartment(e.target.value);
                                        }}
                                        disabled={isDisabled}
                                    >
                                        {initial.departments.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Departamento"
                            value={getValues("originDepartmentDesc")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {originProvinces
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="originProvince"
                                control={control}
                                defaultValue={"1501"}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Provincia"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChangeOriginProvince(e.target.value);
                                        }}
                                        disabled={isDisabled || originProvinces.length === 0}
                                    >
                                        {originProvinces.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Provincia"
                            value={getValues("originProvinceDesc")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {originDistricts
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="originDistrict"
                                control={control}
                                defaultValue={"150101"}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Distrito"
                                        disabled={isDisabled || originDistricts.length === 0}
                                    >
                                        {originDistricts.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Distrito"
                            value={getValues("originDistrictDesc")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 1 }}>Destinatario</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                    {initial.documentTypes
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="deliveryPersonDocumentType"
                                control={control}
                                defaultValue={'6'}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Tipo de documento"
                                        disabled={isDisabled || watchDeliveryPersonId !== null}
                                    >
                                        {initial.documentTypes.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Tipo de documento"
                            value={getValues("deliveryPersonDocumentType")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <Controller
                                name="deliveryPersonDocumentNumber"
                                control={control}
                                rules={{
                                    required: 'Campo requerido'
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Nro. de documento"
                                        error={!!errors.deliveryPersonDocumentNumber}
                                        helperText={errors?.deliveryPersonDocumentNumber?.message}
                                        disabled={isDisabled || watchDeliveryPersonId !== null}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BadgeIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Controller
                                name="deliveryPersonName"
                                control={control}
                                rules={{
                                    required: 'Campo requerido'
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Nombre completo"
                                        error={!!errors.deliveryPersonName}
                                        helperText={errors?.deliveryPersonName?.message}
                                        disabled={isDisabled || watchDeliveryPersonId !== null}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="auto">
                        <FormControl sx={{ mt: 1 }}>
                            {watchDeliveryPersonId
                                ?
                                <Tooltip title="Limpiar">
                                    <Button variant="outlined" onClick={clearDeliveryPerson} disabled={isDisabled}>
                                        <ClearIcon />
                                    </Button>
                                </Tooltip>
                                :
                                <Tooltip title="Buscar">
                                    <Button variant="outlined" onClick={() => setShowModalDeliveryPerson(true)} disabled={isDisabled}>
                                        <SearchIcon />
                                    </Button>
                                </Tooltip>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 1 }}>Llegada</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Controller
                                name="deliveryAddress"
                                control={control}
                                rules={{
                                    required: 'Campo requerido',
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        label="Dirección"
                                        error={!!errors.deliveryAddress}
                                        helperText={errors?.deliveryAddress?.message}
                                        disabled={isDisabled}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MapsHomeWorkIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {initial.departments
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="deliveryDepartment"
                                control={control}
                                defaultValue={"15"}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Departamento"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChangeDeliveryDepartment(e.target.value)
                                        }}
                                        disabled={isDisabled}
                                    >
                                        {initial.departments.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Departamento"
                            value={getValues("deliveryDepartmentDesc")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {deliveryProvinces
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="deliveryProvince"
                                control={control}
                                defaultValue={"1501"}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Provincia"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChangeDeliveryProvince(e.target.value)
                                        }}
                                        disabled={isDisabled || deliveryProvinces.length === 0}
                                    >
                                        {deliveryProvinces.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Provincia"
                            value={getValues("deliveryProvinceDesc")}
                            disabled={true}
                        />
                    }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {deliveryDistricts
                        ?
                        <FormControl fullWidth>
                            <Controller
                                name="deliveryDistrict"
                                control={control}
                                defaultValue={"150101"}
                                render={({ field }) =>
                                    <TextField select
                                        {...field}
                                        label="Distrito"
                                        disabled={isDisabled || deliveryDistricts.length === 0}
                                    >
                                        {deliveryDistricts.map((x) =>
                                            <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                        )}
                                    </TextField>
                                }
                            />
                        </FormControl>
                        :
                        <TextField
                            label="Distrito"
                            value={getValues("deliveryDistrictDesc")}
                            disabled={true}
                        />
                    }
                    </Grid>
                </Grid>
            </Container>
            <AddMenu handleAddItem={handleAddItem} isDisabled={isDisabled} />
            <TableContainer sx={{ mt: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" width="20%">Código</TableCell>
                            <TableCell align="center" >Nombre</TableCell>
                            <TableCell align="center" width="20%">Cantidad</TableCell>
                            <TableCell width={120}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {details.map((detail, index) =>
                        <TableRow key={index}>
                            <TableCell>
                                <FormControl fullWidth>
                                    <Controller
                                        name={`details.${index}.code`}
                                        control={control}
                                        rules={{
                                            required: 'Campo requerido',
                                        }}
                                        render={({ field }) =>
                                            <>
                                                <TextField
                                                    {...field}
                                                    error={!!errors.details?.[index].code}
                                                    helperText={errors?.details?.[index].code?.message}
                                                    disabled={isDisabled || (detail.itemId !== 0)}
                                                />
                                            </>
                                        }
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl fullWidth>
                                    <Controller
                                        name={`details.${index}.name`}
                                        control={control}
                                        rules={{
                                            required: 'Campo requerido',
                                        }}
                                        render={({ field }) =>
                                            <>
                                                <TextField
                                                    {...field}
                                                    error={!!errors.details?.[index].name}
                                                    helperText={errors?.details?.[index].name?.message}
                                                    disabled={isDisabled || (detail.itemId !== 0)}
                                                />
                                            </>
                                        }
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl fullWidth>
                                    <Controller
                                        name={`details.${index}.quantity`}
                                        control={control}
                                        defaultValue={1}
                                        rules={{
                                            required: 'Campo requerido',
                                            validate: {
                                                positive: v => parseInt(v) > 0 || 'Valor inválido'
                                            }
                                        }}
                                        render={({ field }) =>
                                            <>
                                                <TextField
                                                    {...field}
                                                    type="number"
                                                    error={!!errors.details?.[index].quantity}
                                                    helperText={errors?.details?.[index].quantity?.message}
                                                    disabled={isDisabled}
                                                />
                                            </>
                                        }
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                {detail.itemId
                                    ?
                                    <Tooltip title="Clear">
                                        <IconButton onClick={() => clearItem(index)} disabled={isDisabled}>
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Buscar">
                                        <IconButton onClick={() => {
                                            setSelectedIndex(index);
                                            setShowModalItem(true);
                                        }} disabled={isDisabled}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title="Eliminar">
                                    <IconButton onClick={() => handleClickDeleteDetail(index)} disabled={isDisabled}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Container maxWidth="md" disableGutters sx={{ marginLeft: 0 }}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Controller
                            name="comments"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Comentarios"
                                    multiline
                                    rows={2}
                                    disabled={isDisabled}
                                >

                                </TextField>
                            }
                        />
                    </FormControl>
                </Grid>
            </Container>
            <ModalPerson show={showModalOriginPerson} onSelect={selectOriginPerson} onClose={handleOriginPersonClose} flag="C" title="Personas"/>
            <ModalPerson show={showModalDeliveryPerson} onSelect={selectDeliveryPerson} onClose={handleDeliveryPersonClose} flag="C" title="Personas"/>
            <ModalItem show={showModalItem} onSelect={selectItem} onClose={handleItemClose} />
        </>
    );
}

function AddMenu({ handleAddItem, isDisabled }) {
    return (
        <>
            <Button
                startIcon={<AddIcon />}
                onClick={handleAddItem}
                sx={{ mt: 2 }}
                disabled={isDisabled}
            >
                Agregar
            </Button>
        </>
    );
}