import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const usePageTracking = () => {
    const isDev = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
    const location = useLocation();

    useEffect(() => {
        if (!isDev) {
            ReactGA.send("pageview");
        }
    }, [location]);
};

export default usePageTracking;