import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import { DataGrid } from '@mui/x-data-grid';

export default function Common({ show, onSelect, onClose, maxWidth, title, FilterSection, Client, columns, beforeResult, afterInitial, afterResult }) {
    const methods = useForm();
    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const handleSearch = page => {
        setLoading(true);
        if (!beforeResult || beforeResult(methods)) {
            const client = new Client();
            client.search({ ...methods.getValues(), pageNumber: page }).then(
                result => {
                    setLoading(false);
                    setData(afterResult ? afterResult(result) : result);
                },
                error => {
                    setError(error.detail);
                }
            );
        }
    }

    return (
        <Dialog open={show} onClose={onClose} fullWidth maxWidth={maxWidth}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <CommonFilterSection methods={methods} handleSearch={handleSearch} FilterSection={FilterSection} />
                {!error ?
                    (data ?
                        ((data.pageNumber > 0 || data.totalCount > 0) ?
                            <Data columns={columns} loading={loading} data={data} onSelect={onSelect} onSearch={handleSearch} />
                            :
                            <Box sx={{ m: 1 }}>
                                <Typography>No se encontraron resultados</Typography>
                            </Box>
                        )
                        :
                        <LinearProgress sx={{ m: 1 }} />
                    )
                    :
                    <Alert severity="error">{error}</Alert>
                }
            </DialogContent>
        </Dialog>
    );
}

function CommonFilterSection({ methods, handleSearch, FilterSection }) {
    React.useEffect(() => {
        handleSearch(0);
    }, []);

    const handleSubmit = data => {
        handleSearch(0);
    }

    return (        
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <Grid
                    container
                    alignItems="center"
                    spacing={1}
                >
                    <FilterSection />
                </Grid>
            </form>
        </FormProvider>
    );
}

function Data({ columns, loading, data, onSelect, onSearch }) {
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const paginationModel ={
        page: data.pageNumber,
        pageSize: 10,
    };

    function changeSelection(newRowSelectionModel) {
        const selected = data.items.find(x => x.id === newRowSelectionModel[0]);
        onSelect(selected);
    }

    return (data &&
        <DataGrid
            rows={data.items}
            rowCount={data.totalCount}
            columns={columns}
            paginationMode="server"
            loading={loading}
            pageSizeOptions={[10]}
            paginationModel={paginationModel}
            onPaginationModelChange={(newPaginationModel) => {            
                onSearch(newPaginationModel.page);
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
                changeSelection(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
        />
    );
}