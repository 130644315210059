import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { AuthClient } from '../Common/web-api-client.ts';
import LoadingButton from '@mui/lab/LoadingButton';
import ReCAPTCHA from "react-google-recaptcha"
import { Link as RouterLink } from 'react-router-dom';

export default function LoginBox(props) {
    const isDev = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
    const [captchaOk, setCaptchaOk] = React.useState(isDev);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const { control, handleSubmit, formState: { errors }, setValue, getValues, setFocus } = useForm();
    const recaptchaRef = React.useRef();

    React.useEffect(() => {
        if (props.email) {
            setValue("email", props.email);
        }
        else {
            const email = localStorage.getItem("email");
            if (email) {
                setValue("email", email);
                setValue("remember", true);
                setFocus("password");
            }
            else {
                setFocus("email");
            }
        }
    }, []);

    const onChange = () => {
        setCaptchaOk(true);
    }

    const onSubmit = data => {
        setLoading(true);

        if (!isDev) {
            data.reCaptcha = recaptchaRef.current.getValue();
        }

        let client = new AuthClient();
        client.login(data).then(
            result => {
                setError(null);
                if (!props.email) {
                    if (getValues('remember')) {
                        localStorage.setItem("email", data.email);
                    }
                    else {
                        localStorage.clear("email");
                    }
                }
                props.then();
            },
            error => {
                setLoading(false);
                setError(error.detail);
                if (!isDev) {
                    window.grecaptcha.reset();
                    setCaptchaOk(false);
                }
            }
        );
    }
    return (
        <React.Fragment>
            <Typography component="h1" variant="h6">
                Ingresa a tu cuenta
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=''
                        rules={{ required: 'Campo requerido' }}
                        render={({ field: { ref, ...field } }) =>
                            <TextField
                                {...field}
                                inputRef={ref}
                                error={!!errors.email}
                                helperText={errors?.email?.message}
                                label="Correo"
                                margin="normal"
                                disabled={(props.email ? true : false) || loading}
                            />
                        }
                    />
                </FormControl>
                <FormControl fullWidth>
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=''
                        rules={{ required: 'Campo requerido' }}
                        render={({ field: { ref, ...field } }) =>
                            <TextField
                                {...field}
                                type="password"
                                inputRef={ref}
                                error={!!errors.password}
                                helperText={errors?.password?.message}
                                label="Contraseña"
                                margin="normal"
                                disabled={loading}
                            />
                        }
                    />
                </FormControl>
                <Box sx={{ textAlign: "center", mt:1 }}>
                    {!isDev &&
                        <ReCAPTCHA ref={recaptchaRef} sitekey="6LcayEsUAAAAAEqWyTho-Nvm2wT9EJrPU_KFivaG" onChange={onChange} style={{ display: "inline-block" }} />
                    }                    
                </Box>
                {!props.email &&
                    <FormControlLabel
                        control={
                            <Controller
                                name="remember"
                                control={control}
                                render={({ field: props }) =>
                                    <Checkbox
                                        {...props}
                                        checked={!!props.value}
                                        color="primary" />
                                }
                            />
                        }
                        label="Recordar cuenta"
                        disabled={loading}
                    />
                }
                {error &&
                    <Alert severity="error">{error}</Alert>
                }
                <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={!captchaOk || loading}
                >
                    Ingresar
                </LoadingButton>
                {!props.email &&
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                        <Link to="Recuperar-contrasena" component={RouterLink} color="secondary">Recuperar contraseña</Link>
                    </Grid>  
                }              
            </form>
        </React.Fragment>
    );
}