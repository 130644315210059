import * as React from 'react';
import { useNavigate } from "react-router-dom";
import LoginBox from './LoginBox';

export default function Login() {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/Principal");
    }
    return (
        <LoginBox then={goToHome} />
    );
}
