import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Theme from 'components/Theme';

const isDev = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
if (!isDev) {
    ReactGA.initialize("G-WLX92N0ZGH");
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename={baseUrl}>
        <Theme>
            <App />
        </Theme>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
