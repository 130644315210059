import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import { Controller, useFormContext } from 'react-hook-form';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
export default function Content({ initial, Client }) {
    const { control, formState: { errors }, setValue, getValues, watch } = useFormContext();
    const [loadingRate, setLoadingRate] = React.useState(false);

    const watchId = watch("id", null);

    const getOnlineExchangeRate = () => {
        const input = {
            currencyId: getValues('currencyId'),
            date: getValues('date'),
        };
        const client = new Client();
        setLoadingRate(true);
        client.getOnlineExchangeRate(input).then(
            result => {
                setValue('rate', result.rate ?? 0);
                setValue('saleRate', result.saleRate ?? 0);
                setLoadingRate(false);
            },
            error => {
                setValue('rate', 0);
                setValue('saleRate', 0);
                setLoadingRate(false);
            }
        );
    }

    React.useEffect(() => {
        if (initial && !watchId) {
            getOnlineExchangeRate();
        }
    }, [initial]);

    return (initial &&
        <Container maxWidth="xs" disableGutters sx={{ marginLeft: 0 }}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="date"
                            control={control}
                            defaultValue={dayjs().utc().startOf('day')}
                            rules={{ required: 'Campo requerido' }}
                            render={({ field: { ref, ...field } }) =>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" dateLibInstance={dayjs.utc}>
                                    <DatePicker
                                        {...field}
                                        inputRef={ref}
                                        label="Fecha"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            getOnlineExchangeRate();
                                        }}
                                        disabled={watchId || loadingRate}
                                        maxDate={dayjs().utc().startOf('day')}
                                        slotProps={{
                                            textField: {
                                                error: !!errors.date,
                                                helperText: errors?.date?.message,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="currencyId"
                            control={control}
                            defaultValue={initial.currencies[0].id}
                            render={({ field }) =>
                                <TextField select
                                    {...field}
                                    label="Moneda"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        getOnlineExchangeRate();
                                    }}
                                    disabled={watchId || loadingRate}
                                >
                                    {initial.currencies.map((x) =>
                                        <MenuItem key={x.id} value={x.id}>{x.desc}</MenuItem>
                                    )}
                                </TextField>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="rate"
                            control={control}
                            defaultValue={0}
                            rules={{
                                required: 'Campo requerido',
                                validate: {
                                    positive: v => parseInt(v) > 0 || 'Valor inválido'
                                }
                            }}
                            render={({ field }) =>
                                <>
                                    <TextField
                                        {...field}
                                        label="Valor compra"
                                        error={!!errors.rate}
                                        helperText={errors?.rate?.message}
                                        disabled={loadingRate}
                                    />
                                    {loadingRate &&
                                        <LinearProgress />
                                    }
                                </>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="saleRate"
                            control={control}
                            defaultValue={0}
                            rules={{
                                required: 'Campo requerido',
                                validate: {
                                    positive: v => parseInt(v) > 0 || 'Valor inválido'
                                }
                            }}
                            render={({ field }) =>
                            <>
                                    <TextField
                                        {...field}
                                        label="Valor venta"
                                        error={!!errors.saleRate}
                                        helperText={errors?.saleRate?.message}
                                        disabled={loadingRate}
                                    />
                                    {loadingRate &&
                                        <LinearProgress />
                                    }
                            </>
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    );
}
