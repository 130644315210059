import React, { Component, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './App.scss';
import usePageTracking from './usePageTracking'

export default function App () {
    //static displayName = App.name;
    usePageTracking();
    return (
        <Suspense fallback={<></>}>
            <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, path, routes } = route;
                    return (
                        <Route key={index} element={element} path={path}>
                            {routes && routes.map((route, index) => 
                                <Route key={index} {...route}/>
                            )}
                        </Route>
                    )
                })}
            </Routes>
        </Suspense>
    );
}