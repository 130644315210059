import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSearchParams, useParams, useNavigate, useLocation } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es';
import Snackbar from '@mui/material/Snackbar';
import MenuActions from './MenuActions';
import ThemeContext from 'components/ThemeContext';

dayjs.extend(utc);

export default function Edit({ Client, ContentSection, beforeResult, afterResult, inputInitial, afterInitial }) {
    const { setLoadingGlobal } = React.useContext(ThemeContext);
    const methods = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [initial, setInitial] = React.useState(null);
    const [data, setData] = React.useState(null);
    const location = useLocation();
    const [success, setSuccess] = React.useState(location.state?.success);
    const [error, setError] = React.useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const [errorInitial, setErrorInitial] = React.useState(false);
    const baseUrl = useLocation().pathname.split('/').slice(0, 3).join('/');

    React.useEffect(() => {
        const option = +searchParams.get('option');
        methods.setValue('option', option);
        methods.setValue('profile', +searchParams.get('profile') ?? 0);
        methods.setValue('id', id);
        loadInitial();
    }, []);

    const loadInitial = () => {
        setErrorInitial(false);
        const input = {
            ...inputInitial ? inputInitial(searchParams) : {},
            option: +methods.getValues('option'),
            id: id,
        };
        const client = new Client();
        client.initialEdit(input).then(
            result => {
                if (afterInitial) {
                    afterInitial(methods.setValue, result, searchParams);
                }
                setInitial(result);

                if (result.object) {
                    for (var prop in result.object) {
                        const date = dayjs.utc(result.object[prop], 'DD/MM/YYYY', true);
                        methods.setValue(prop, date.isValid() ? date : result.object[prop]);
                    }
                }
            },
            error => {
                setErrorInitial(true);
            }
        );
    }

    const handleSave = () => {
        if (!beforeResult || beforeResult(methods.setValue)) { //if beforeResult exists, then should return true to continue
            const client = new Client();
            setLoadingGlobal(true);
            client.save({ ...methods.getValues()}).then(
                result => {
                    setLoadingGlobal(false);
                    navigate({
                        pathname: baseUrl,
                        search: searchParams.toString(),                        
                    }, {
                        state: { success: result }
                    });
                    //afterResult && afterResult(methods.setError, error);
                },
                error => {
                    setLoadingGlobal(false);
                    if (Object.keys(error.errors).length === 0) {
                        setError([error.detail]);
                    }
                    else {
                        const _errors = [];
                        for (var _error in error.errors) {
                            _errors.push(error.errors[_error][0]);
                        }
                        setError(_errors.join('\n'));
                    }                  
                }
            );
        }
    }

    return (
        <>
            <FormProvider {...methods} >
                <form>
                    <MenuActions Client={Client} handleSave={handleSave} initial={initial} id={id} setError={setError} setSuccess={setSuccess}>
                        <Paper sx={{ m: 1, p: 1, mt: 0 }}>
                            {errorInitial &&
                                <Button startIcon={<ReplayIcon />} onClick={loadInitial}>Volver a cargar página</Button>
                            }
                            {initial ?
                                <ContentSection initial={initial} Client={Client} />
                                :
                                <>
                                    <Skeleton width={200} sx={{ my: 1 }} />
                                    <Skeleton width={200} sx={{ my: 1 }} />
                                    <Skeleton width={200} sx={{ my: 1 }} />
                                    <Skeleton width={200} sx={{ my: 1 }} />
                                </>
                            }
                        </Paper>
                    </MenuActions>
                </form>
                {error &&
                    <Snackbar open={error} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setError(null)} >
                        <Alert severity="error" onClose={() => setError(null)} sx={{ whiteSpace: 'pre-line' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                }
                {success &&
                    <Snackbar open={success} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setSuccess(null)} >
                        <Alert severity="success" onClose={() => setSuccess(null)}>
                            {success}
                        </Alert>
                    </Snackbar>
                }
            </FormProvider>
        </>
    );
}