import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { AuthClient } from '../Common/web-api-client.ts';
import LoadingButton from '@mui/lab/LoadingButton';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword(props) {
    const isDev = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
    const [captchaOk, setCaptchaOk] = React.useState(isDev);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const { control, handleSubmit, formState: { errors }, setValue, getValues, setFocus } = useForm();
    const recaptchaRef = React.useRef();
    const navigate = useNavigate();

    const onChange = () => {
        setCaptchaOk(true);
    }

    const onSubmit = data => {
        setLoading(true);

        if (!isDev) {
            data.reCaptcha = recaptchaRef.current.getValue();
        }
        
        let client = new AuthClient();
        client.forgotPassword(data).then(
            result => {
                navigate("../Contrasena-enviada");
            },
            error => {
                setLoading(false);
                setError(error.response);
                if (!isDev) {
                    window.grecaptcha.reset();
                    setCaptchaOk(false);
                }
            }
        );
    }
    return (
        <React.Fragment>
            <Typography component="h1" variant="h6">
                Recuperar contraseña
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=''
                        rules={{ required: 'Campo requerido' }}
                        render={({ field: { ref, ...field } }) =>
                            <TextField
                                {...field}
                                inputRef={ref}
                                error={!!errors.email}
                                helperText={errors?.email?.message}
                                label="Correo"
                                margin="normal"
                                disabled={loading}
                            />
                        }
                    />
                </FormControl>
                <Box sx={{ textAlign: "center", mt: 1 }}>
                    {!isDev &&
                        <ReCAPTCHA ref={recaptchaRef} sitekey="6LcayEsUAAAAAEqWyTho-Nvm2wT9EJrPU_KFivaG" onChange={onChange} style={{ display: "inline-block" }} />
                    }
                </Box>
                {error &&
                    <Alert severity="error">{error}</Alert>
                }
                <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={!captchaOk || loading}
                >
                    Enviar enlace
                </LoadingButton>
            </form>
        </React.Fragment>
    );
}