import { lazy } from 'react';
import { FetchData } from "components/FetchData";
import { Navigate } from 'react-router-dom';
import Login from 'components/Login/Login';
import ForgotPassword from 'components/Login/ForgotPassword';
import PasswordSent from 'components/Login/PasswordSent';
import ResetPassword from 'components/Login/ResetPassword';
import ExchangeRateNew from 'components/Configuration/ExchangeRate/ExchangeRateNew';
import ExchangeRateEdit from 'components/Configuration/ExchangeRate/ExchangeRateEdit';
import ExchangeRateDelete from 'components/Configuration/ExchangeRate/ExchangeRateDelete';
import RemissionGuideNew from 'components/Sales/RemissionGuide/RemissionGuideNew';
import RemissionGuideEdit from 'components/Sales/RemissionGuide/RemissionGuideEdit';
import RemissionGuideDelete from 'components/Sales/RemissionGuide/RemissionGuideDelete';
const Home = lazy(() => import('components/Home/Home'));
const LoginTemplate = lazy(() => import('components/Login/LoginTemplate'));
const Kardex = lazy(() => import('components/Report/Kardex'));
const AnalysisAccountCostCenter = lazy(() => import('components/Report/AnalysisAccountCostCenter'));
const AnalysisAccountDocument = lazy(() => import('components/Report/AnalysisAccountDocument'));
const SalesDetail = lazy(() => import('components/Report/SalesDetail'));
const ArDocument = lazy(() => import('components/Account/ArDocument'));
const ApDocument = lazy(() => import('components/Account/ApDocument'));
const TeTransaction = lazy(() => import('components/Account/TeTransaction'));
const WhTransaction = lazy(() => import('components/Account/WhTransaction'));
const ExchangeRate = lazy(() => import('components/Configuration/ExchangeRate/ExchangeRate'));
const RemissionGuide = lazy(() => import('components/Sales/RemissionGuide/RemissionGuide'));

const AppRoutes = [
    {
        index: true,
        path: '/',
        element: <Navigate to="/Login" replace />
    },
    {
        path: '/Login',
        element: <LoginTemplate />,
        routes: [
            {
                path: '',
                element: <Login />
            },
            {
                path: 'Recuperar-contrasena',
                element: <ForgotPassword />
            },
            {
                path: 'Contrasena-enviada',
                element: <PasswordSent/>
            },
            {
                path: 'Reset-contrasena',
                element: <ResetPassword />
            }
        ]
    },
    {
        path: '/Principal',
        element: <Home />
    },
    {
        path: '/fetchdata',
        element: <FetchData />
    },
    {
        path: '/Report/Kardex',
        element: <Kardex />
    },
    {
        path: '/Report/AnalysisAccountCostCenter',
        element: <AnalysisAccountCostCenter />
    },
    {
        path: '/Report/AnalysisAccountDocument',
        element: <AnalysisAccountDocument />
    },
    {
        path: '/Report/SalesDetail',
        element: <SalesDetail />
    },
    {
        path: '/Account/ArDocument',
        element: <ArDocument />
    },
    {
        path: '/Account/ApDocument',
        element: <ApDocument />
    },
    {
        path: '/Account/TeTransaction',
        element: <TeTransaction />
    },
    {
        path: '/Account/WhTransaction',
        element: <WhTransaction />
    },
    {
        path: '/Configuration/ExchangeRate',
        routes: [
            {
                path: '',
                element: <ExchangeRate />
            },
            {
                path: 'Agregar',
                element: <ExchangeRateNew />
            },
            {
                path: 'Editar/:id',
                element: <ExchangeRateEdit />
            },
            {
                path: 'Eliminar/:id',
                element: <ExchangeRateDelete />
            }
        ]
    },
    {
        path: '/Sales/RemissionGuide',
        routes: [
            {
                path: '',
                element: <RemissionGuide />
            },
            {
                path: 'Agregar',
                element: <RemissionGuideNew />
            },
            {
                path: 'Editar/:id',
                element: <RemissionGuideEdit />
            },
            {
                path: 'Eliminar/:id',
                element: <RemissionGuideDelete />
            }
        ]
    },
];

export default AppRoutes;
