import * as React from 'react';
import { ItemClient } from '../Common/web-api-client.ts';
import Common from './Common';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function ModalItem ({ show, onSelect, onClose }) {
    const columns = [
        { field: 'code', headerName: 'Código', flex: 1, sortable: false },
        { field: 'name', headerName: 'Nombre', flex: 2, sortable: false },
    ];

    return (
        <Common
            show={show} onSelect={onSelect} onClose={onClose} title="Artículos"
            FilterSection={Filter}
            Client={ItemClient}
            columns={columns}
        />
    );
}

function Filter() {
    const { control } = useFormContext();

    return (
        <>
            <Grid item sm={5} >
                <FormControl fullWidth>
                    <Controller
                        name="code"
                        control={control}
                        defaultValue=''
                        render={({ field: { ref, ...field } }) =>
                            <TextField
                                inputRef={ref}
                                label="Código"
                                {...field}
                            />
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item sm={6} >
                <FormControl fullWidth>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=''
                        render={({ field: { ref, ...field } }) =>
                            <TextField
                                inputRef={ref}
                                label="Nombre"
                                {...field}
                            />
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item sm={1} >
                <FormControl fullWidth>
                    <IconButton type="submit">
                        <SearchIcon />
                    </IconButton>
                </FormControl>
            </Grid>
        </>
    );
}